import React, { FunctionComponent, useEffect, useRef, Suspense } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route as ReactRouterRoute, Switch, Redirect, RouteProps, useLocation, useRouteMatch } from 'react-router-dom';
import lazyRoutes from 'scripts/config/routes/lazy-route-exports';
import { selectProfileData, selectProfileLoading, selectProfileError } from '../selectors/profile-service-selectors';
import {
  selectClientConfigData,
  selectClientConfigLoading,
  selectClientConfigError,
} from '../selectors/targeting-service-selectors';
import { selectHeartbeatData } from 'scripts/selectors/user-service-selectors';
import { getProfile } from 'scripts/thunks/profile-service-thunks';
import { getClientConfig } from 'scripts/thunks/targeting-service-thunks';
import { LoadingBar } from 'scripts/ui/loading-bar/loading-bar';
import { PagesData } from 'scripts/config/pages-data';
import { EIAccountSummaryExplanation } from 'scripts/features/modals/account-summary/ei-account-summary-explanation';
import { AnalyticsWithData, UnauthAnalytics } from 'scripts/features/analytics/analytics';
import { DeterminePopulationByProfile } from 'scripts/features/population/population';
import { isModalPath } from 'scripts/util/uri/uri';
import { resetFocus, scrollToTop } from 'scripts/util/browser/browser';
import { selectThemeName } from 'scripts/selectors/app-selectors';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { RouteTracking } from 'scripts/config/route-tracking';
import { changeNavOpen } from 'scripts/thunks/loading-bar-thunks';
import styled, { css, keyframes } from 'styled-components';
import { container } from 'scripts/styles/utilities';
import { Locale } from 'scripts/ui/locale/locale';
import { IEmptyComponent } from 'scripts/features/general.interfaces';
import { setIsUserActive } from 'scripts/reducers/app-reducer';
import { usePopulationByPath } from 'scripts/hooks/use-population-by-path/use-population-by-path';
import { useProfileLoaded } from 'scripts/hooks/use-profile-loaded/use-profile-loaded';
import { LoadingDots } from 'scripts/ui/loading-dots/loading-dots';
import { fromTheme } from 'scripts/styles/themes/themes.utils';

export interface IRouteProps extends RouteProps {
  protectedRoute: boolean;
  title?: string | string[];
}

interface IAuthenticatedRouteProps extends Omit<IRouteProps, 'protectedRoute'> {}

export const Routes: FunctionComponent = () => {
  const basePath = '/:path?';
  const modalPath = `${basePath}/*`;

  return (
    <SuspenseWrapper>
      <ModalRoute path={`${modalPath}${PagesData.callToChangePcp.path}`} title={PagesData.callToChangePcp.title}>
        <lazyRoutes.CallToChangePcpModal />
      </ModalRoute>
      <ModalRoute path={`${modalPath}${PagesData.idCards.path}`} title={PagesData.idCards.title}>
        <lazyRoutes.IdCard />
      </ModalRoute>
      <ModalRoute path={`${modalPath}${PagesData.overviewVideo.path}`} title={PagesData.overviewVideo.title}>
        <lazyRoutes.ActivateVideoModal />
      </ModalRoute>
      <ModalRoute path={`${modalPath}${PagesData.overviewTranscript.path}`} title={PagesData.overviewTranscript.title}>
        <lazyRoutes.ActivateTranscriptModal />
      </ModalRoute>
      <ModalRoute
        path={`${modalPath}${PagesData.accountSummaryExplanation.path}`}
        title={PagesData.accountSummaryExplanation.title}
      >
        <EIAccountSummaryExplanation />
      </ModalRoute>
      <ModalRoute path={`${modalPath}${PagesData.payNow.path}`} title={PagesData.payNow.title}>
        <lazyRoutes.PayNowModal />
      </ModalRoute>
      <ModalRoute
        path={`${modalPath}${PagesData.idCardAccessibility.path}`}
        title={PagesData.idCardAccessibility.title}
      >
        <lazyRoutes.IDCardAccessibilityModal />
      </ModalRoute>
      <ModalRoute
        path={`${modalPath}${PagesData.pcpChangeInProgress.path}`}
        title={PagesData.pcpChangeInProgress.title}
      >
        <lazyRoutes.PcpChange />
      </ModalRoute>
      <ModalRoute
        path={`${modalPath}${PagesData.pcpChangeUnavailable.path}`}
        title={PagesData.pcpChangeUnavailable.title}
      >
        <lazyRoutes.PcpChangeUnavailableModal />
      </ModalRoute>
      <ModalRoute
        path={`${modalPath}${PagesData.waysToSaveModal.path}/:campaignName`}
        title={PagesData.waysToSaveModal.title}
      >
        <lazyRoutes.WaysToSaveModal />
      </ModalRoute>
      <ModalRoute path={`${modalPath}${PagesData.mrNudgeModal.path}`} title={PagesData.mrNudgeModal.title}>
        <lazyRoutes.MrNudgeModal />
      </ModalRoute>
      <ModalRoute path={`${modalPath}${PagesData.mrPost65NudgeModal.path}`} title={PagesData.mrPost65NudgeModal.title}>
        <lazyRoutes.MrPost65NudgeModal />
      </ModalRoute>
      {/* Non-modal Routes */}
      <Switch>
        <lazyRoutes.LoginRoute path={PagesData.login.path} />
        <lazyRoutes.InternalErrorRoute path={`${basePath}${PagesData.internalError.path}`} />
        <lazyRoutes.SeeYouLaterRoute path={`${basePath}${PagesData.seeYouLater.path}`} />
        <AuthenticatedRoute path={`${basePath}${PagesData.coronavirus.path}`} title={PagesData.coronavirus.title}>
          <lazyRoutes.Coronavirus />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          path={`${basePath}${PagesData.healthResources.path}`}
          title={PagesData.healthResources.title}
        >
          <lazyRoutes.HealthResources />
        </AuthenticatedRoute>
        <AuthenticatedRoute path={`${basePath}${PagesData.ovc.path}`} title={PagesData.ovc.title}>
          <Redirect to={PagesData.optumVirtualCare.path} />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          path={`${basePath}${PagesData.optumVirtualCare.path}`}
          title={PagesData.optumVirtualCare.title}
        >
          <lazyRoutes.OptumVirtualCareContainer />
        </AuthenticatedRoute>
        <AuthenticatedRoute path={`${basePath}${PagesData.pharmacy.path}`} title={PagesData.pharmacy.title}>
          <lazyRoutes.Pharmacy />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          path={`${basePath}${PagesData.recommendations.path}`}
          title={PagesData.recommendations.title}
        >
          <lazyRoutes.Recommendations />
        </AuthenticatedRoute>

        <AuthenticatedRoute path={`${basePath}${PagesData.help.path}`} title={PagesData.help.title}>
          <lazyRoutes.Help />
        </AuthenticatedRoute>
        <AuthenticatedRoute path={`${basePath}${PagesData.pcpReferrals.path}`} title={PagesData.pcpReferrals.title}>
          <lazyRoutes.ProviderReferrals />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          path={`${basePath}${PagesData.idCardspaperlessPreferences.path}`}
          title={PagesData.idCardspaperlessPreferences.title}
        >
          <lazyRoutes.IdCardsPaperlessPreferences />
        </AuthenticatedRoute>
        <AuthenticatedRoute path={`${basePath}${PagesData.waysToSave.path}`} title={PagesData.waysToSave.title}>
          <lazyRoutes.ConnectedWaysToSave />
        </AuthenticatedRoute>
        <AuthenticatedRoute path={`${basePath}${PagesData.liveChat.path}`} title={PagesData.liveChat.title}>
          <lazyRoutes.ChatterBoxContainer />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          path={`${basePath}${PagesData.premiumPayments.path}`}
          title={PagesData.premiumPayments.title}
        >
          <lazyRoutes.PremiumPayments />
        </AuthenticatedRoute>
        <AuthenticatedRoute path={`${basePath}${PagesData.directSsoGenoa.path}`} title={PagesData.directSsoGenoa.title}>
          <lazyRoutes.GenoaSsoRedirect />
        </AuthenticatedRoute>
        <ReactRouterRoute path={`${basePath}${PagesData.internalRedirect.path}`}>
          <DocumentTitle title={PagesData.internalRedirect.title} />
          <PathnameValidation />
          <lazyRoutes.InternalRedirect />
        </ReactRouterRoute>
        <AuthenticatedRoute
          path={`${basePath}${PagesData.orderCovidTests.path}`}
          title={PagesData.orderCovidTests.title}
        >
          <lazyRoutes.OrderCovidTests basePath={basePath} />
        </AuthenticatedRoute>

        <lazyRoutes.LogoutRoute path={`${basePath}${PagesData.logout.path}`} />

        <lazyRoutes.UnauthorizedErrorRoute path={`${basePath}${PagesData.unauthorizedError.path}`} />

        {/**
         * The two dashboard routes exist to handle how we handle modals,
         * ensuring the background is rendered for modals and invalid routes are caught and redirected
         */}
        {/* only /dashboard path is caught */}

        <lazyRoutes.DashboardRoute exact path={`${basePath}${PagesData.dashboard.path}`} />

        {/* catches routes such as /dashboard/modal/* and /dashboard/nonsense */}

        <lazyRoutes.DashboardRoute path={`${basePath}${PagesData.dashboard.path}`} />

        {/*
         * handles invalid routes that match no other routes --
         * ex. /nonsense => /dashboard
         * /nonsense/undefined => /dashboard
         * /nonsense/modal/id-cards => /dashboard
         */}
        <Redirect
          to={{
            pathname: PagesData.dashboard.path,
            search: window.location.search,
          }}
        />
      </Switch>
    </SuspenseWrapper>
  );
};

/* WHAT IS BEING AUTHENTICATED? AND WHAT IS A PROTECTED ROUTE? */
/* AUTHENTICATED: If a user is authenticated (logged in and certified)
 * PROTECTED ROUTE: A route of our app that expects a user to be authenticated
 */
export const Route: FunctionComponent<IRouteProps> = props => {
  const { children, title, protectedRoute, ...rest } = props;
  const hasProfileLoaded = useProfileLoaded();

  const canStripLocaleFromUrl = (protectedRoute && hasProfileLoaded) || !protectedRoute;

  return (
    <ReactRouterRoute {...rest}>
      <DocumentTitle title={title} />
      <PathnameValidation />
      <Locale shouldRedirect={canStripLocaleFromUrl}>
        <>{children}</>
      </Locale>
    </ReactRouterRoute>
  );
};

const Authenticated: FunctionComponent = props => {
  const { children } = props;
  const profile = useSelector(selectProfileData);
  const profileLoading = useSelector(selectProfileLoading);
  const profileError = useSelector(selectProfileError);
  const clientConfig = useSelector(selectClientConfigData);
  const clientConfigLoading = useSelector(selectClientConfigLoading);
  const clientConfigError = useSelector(selectClientConfigError);
  const heartBeatData = useSelector(selectHeartbeatData);
  const dispatch = useDispatch();
  const isAuthenticated = !!(profile && clientConfig);

  useEffect(() => {
    if (heartBeatData && !profile && !profileLoading && !profileError) {
      dispatch(getProfile());
    }
  }, [heartBeatData, profile, profileLoading, profileError]);

  useEffect(() => {
    // we need to ensure profile has a chance to load or clientConfig won't be fetched
    if (profile && !clientConfig && !clientConfigLoading && !clientConfigError) {
      dispatch(getClientConfig());
    }
  }, [profile, clientConfig, clientConfigLoading, clientConfigError]);

  return (
    <>
      {isAuthenticated && (
        <>
          <AnalyticsWithData />
          <LoadingBar />
          <DeterminePopulationByProfile />
          <RouteChange>
            <RouteTracking>{children}</RouteTracking>
          </RouteChange>
        </>
      )}
    </>
  );
};

export const delayKF = keyframes`
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
`;
const delay = css`
  animation: ${delayKF} 0s;
  // "anything < 1s keeps the user's flow of thought seamless": https://www.nngroup.com/articles/website-response-times/
  animation-delay: 0.8s;
  animation-fill-mode: both;
`;
const $LoadingPage = styled.div`
  & > div {
    ${delay}
  }
  background-color: white;
  height: 100vh;
  left: 0px;
  padding: 0 ${fromTheme('spacing16')};
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1001;
`;
const $LoadingSection = styled.div`
  ${container}
  ${delay}
`;

export const SuspenseWrapper: FunctionComponent = props => {
  const { children } = props;
  return (
    <Suspense
      fallback={
        <$LoadingPage>
          <LoadingDots />
        </$LoadingPage>
      }
    >
      {children}
    </Suspense>
  );
};

export const SectionsSuspenseWrapper: FunctionComponent = props => {
  const { children } = props;
  return (
    <Suspense
      fallback={
        <$LoadingSection>
          <LoadingDots />
        </$LoadingSection>
      }
    >
      {children}
    </Suspense>
  );
};

export const AuthenticatedRoute: FunctionComponent<IAuthenticatedRouteProps> = props => {
  const { children, ...rest } = props;
  return (
    <Route protectedRoute={true} {...rest}>
      <Authenticated>{children}</Authenticated>
    </Route>
  );
};

const ModalRoute: FunctionComponent<IAuthenticatedRouteProps> = props => {
  const { children, ...rest } = props;
  return (
    <Route protectedRoute={true} {...rest}>
      <Authenticated>{createPortal(children, document.body)}</Authenticated>
    </Route>
  );
};

export const UnauthenticatedRoute: FunctionComponent<IEmptyComponent> = () => {
  const dispatch = useDispatch();
  usePopulationByPath();

  useEffect(() => {
    dispatch(setIsUserActive(false));
  }, []);

  return <UnauthAnalytics />;
};

const RouteChange: FunctionComponent = ({ children }) => {
  const { pathname } = useLocation();
  const previousPathnameRef = useRef(pathname || '');
  const dispatch = useDispatch();

  useEffect(() => {
    // only need if going from non-modal to non-modal
    if (!isModalPath(previousPathnameRef.current) && !isModalPath(pathname)) {
      scrollToTop();
      resetFocus();
    }
    // ARC-12817 When navigating from hamburger menu to new route, need to remove CSS showing modal and hiding body
    dispatch(changeNavOpen(false));
    previousPathnameRef.current = pathname;
  }, [pathname]);

  return <>{children}</>;
};

const DocumentTitle: FunctionComponent<Pick<IRouteProps, 'title'> & IEmptyComponent> = ({ title }): null => {
  const themeName = useSelector(selectThemeName);
  const { isExact } = useRouteMatch();
  const { t, i18n } = useTranslation(Dictionary.PAGE_TITLES);
  // Workaround to fix race condition where this component renders before i18n loads translations
  const currentLanguage = i18n.language;

  useEffect(() => {
    // isExact check is needed for modals, which are rendered outside of Switch
    if (isExact) {
      const pageTitle = Array.isArray(title) ? title.map(t).join(' - ') : t(title);
      const suffix = ' | UnitedHealthcare';
      document.title = pageTitle + suffix;
    }
  }, [themeName, isExact, title, currentLanguage]);

  return null;
};

const PathnameValidation: FunctionComponent<IEmptyComponent> = () => {
  const { pathname, search } = useLocation<{ pathname: string; search: string }>();
  const trailingSlashRegEx = /\/+$/;
  const internalSlashesRegEx = /\/\/+/g;

  // The below validation turns '/' => '', introducing an un-routeable loop bug, this redirects us to the Dashboard instead
  if (pathname === '' || pathname === '/') {
    return (
      <Redirect
        to={{
          pathname: '/dashboard',
          search,
        }}
      />
    );
  }

  if (pathname.match(trailingSlashRegEx) || pathname.match(internalSlashesRegEx)) {
    return (
      // Redirect since we're not on RR V6
      <Redirect
        to={{
          /**
           * Replaces not only trailing slashes but also excessive internal slashes
           * Ex.
           * /dashboard/ => /dashboard
           * /dashboard/// => /dashboard
           * /dashboard///modal/id-cards// => /dashboard/modal/id-cards
           * /dashboard//modal => /dashboard/modal
           * https://github.com/remix-run/react-router/issues/4841
           * https://github.com/remix-run/react-router/discussions/8022
           */
          pathname: pathname.replace(trailingSlashRegEx, '').replace(internalSlashesRegEx, '/'),
          search,
        }}
      />
    );
  }

  return null;
};
