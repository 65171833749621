import {
  IClaimsPreferences,
  ICommunicationPreferences,
  IDependentAndCoverageTypes,
  IProducts,
  IProfile,
  IProfileUser,
  IReferralsPerMember,
  IPrimaryCareFpc,
  IPrimaryCarePerMember,
  IProviderVideo,
  IFinancialPreferences,
  IOrderCovidTestKitInfo,
  IAccountCard,
  IPermissions,
  ICspaRewards,
} from 'scripts/api/profile/profile.interfaces';
import { IStateData } from 'scripts/reducers/reducer.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IProfileState {
  profile: IStateData<IProfile>;
  selectedProfile: IProfileUser;
  claimsPreferences: IStateData<IClaimsPreferences>;
  communicationPreferences: IStateData<ICommunicationPreferences>;
  products: IStateData<IProducts>;
  referrals: IStateData<IReferralsPerMember>;
  healthcareCoverages: IStateData<IDependentAndCoverageTypes[]>;
  fpcPrimaryCare: IStateData<IPrimaryCareFpc>;
  primaryCare: IStateData<IPrimaryCarePerMember>;
  providerVideoKey: IStateData<IProviderVideo>;
  financialPreferences: IStateData<IFinancialPreferences>;
  profileInfoForRallyPay: IStateData<string>;
  orderCovidTestsInfo: IStateData<IOrderCovidTestKitInfo>;
  accountCards: IStateData<IAccountCard[]>;
  uhcRewards: IStateData<boolean>;
  medicareNudgeEligibility: IStateData<boolean>;
  permissions: IStateData<IPermissions[]>;
  cspaRewards: IStateData<ICspaRewards>;
}

export const initialState: IProfileState = {
  profile: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  profileInfoForRallyPay: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  selectedProfile: undefined,
  claimsPreferences: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  communicationPreferences: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  products: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  referrals: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  healthcareCoverages: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  fpcPrimaryCare: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  primaryCare: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  providerVideoKey: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  financialPreferences: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  orderCovidTestsInfo: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  accountCards: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  uhcRewards: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  medicareNudgeEligibility: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  permissions: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  cspaRewards: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

const profile = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getProfileSuccess: (
      state: IProfileState,
      action: PayloadAction<{ profile: IProfile; selectedProfile: IProfileUser }>,
    ): void => {
      state.profile.data = action.payload.profile;
      state.profile.error = false;
      state.profile.loading = false;
      state.selectedProfile = action.payload.selectedProfile;
    },
    getProfileLoading: (state: IProfileState): void => {
      state.profile.loading = true;
    },
    getProfileError: (state: IProfileState): void => {
      state.profile.data = undefined;
      state.profile.error = true;
      state.profile.loading = false;
    },
    changeSelectedProfile: (state: IProfileState, action: PayloadAction<IProfileUser>): void => {
      state.selectedProfile = action.payload;
    },
    getClaimsPreferencesSuccess: (state: IProfileState, action: PayloadAction<IClaimsPreferences>): void => {
      state.claimsPreferences.data = action.payload;
      state.claimsPreferences.error = false;
      state.claimsPreferences.loading = false;
    },
    getClaimsPreferencesLoading: (state: IProfileState): void => {
      state.claimsPreferences.loading = true;
    },
    getClaimsPreferencesError: (state: IProfileState): void => {
      state.claimsPreferences.data = undefined;
      state.claimsPreferences.error = true;
      state.claimsPreferences.loading = false;
    },
    getCommunicationPreferencesSuccess: (
      state: IProfileState,
      action: PayloadAction<ICommunicationPreferences>,
    ): void => {
      state.communicationPreferences.data = action.payload;
      state.communicationPreferences.error = false;
      state.communicationPreferences.loading = false;
    },
    getCommunicationPreferencesLoading: (state: IProfileState): void => {
      state.communicationPreferences.loading = true;
    },
    getCommunicationPreferencesError: (state: IProfileState): void => {
      state.communicationPreferences.data = undefined;
      state.communicationPreferences.error = true;
      state.communicationPreferences.loading = false;
    },
    getProductsSuccess: (state: IProfileState, action: PayloadAction<IProducts>): void => {
      state.products.data = action.payload;
      state.products.error = false;
      state.products.loading = false;
    },
    getProductsLoading: (state: IProfileState): void => {
      state.products.loading = true;
    },
    getProductsError: (state: IProfileState): void => {
      state.products.data = undefined;
      state.products.error = true;
      state.products.loading = false;
    },
    getCspaRewardsSuccess: (state: IProfileState, action: PayloadAction<ICspaRewards>): void => {
      state.cspaRewards.data = action.payload;
      state.cspaRewards.error = false;
      state.cspaRewards.loading = false;
    },
    getCspaRewardsLoading: (state: IProfileState): void => {
      state.cspaRewards.loading = true;
    },
    getCspaRewardsError: (state: IProfileState): void => {
      state.cspaRewards.data = undefined;
      state.cspaRewards.error = true;
      state.cspaRewards.loading = false;
    },
    getReferralsSuccess: (state: IProfileState, action: PayloadAction<IReferralsPerMember>): void => {
      state.referrals.data = action.payload;
      state.referrals.error = false;
      state.referrals.loading = false;
    },
    getReferralsLoading: (state: IProfileState): void => {
      state.referrals.loading = true;
    },
    getReferralsError: (state: IProfileState): void => {
      state.referrals.data = undefined;
      state.referrals.error = true;
      state.referrals.loading = false;
    },
    getHealthcareCoveragesSuccess: (
      state: IProfileState,
      action: PayloadAction<IDependentAndCoverageTypes[]>,
    ): void => {
      state.healthcareCoverages.data = action.payload;
      state.healthcareCoverages.error = false;
      state.healthcareCoverages.loading = false;
    },
    getHealthcareCoveragesLoading: (state: IProfileState): void => {
      state.healthcareCoverages.loading = true;
    },
    getHealthcareCoveragesError: (state: IProfileState): void => {
      state.healthcareCoverages.data = undefined;
      state.healthcareCoverages.error = true;
      state.healthcareCoverages.loading = false;
    },
    getFpcPrimaryCareSuccess: (state: IProfileState, action: PayloadAction<IPrimaryCareFpc>): void => {
      state.fpcPrimaryCare.data = action.payload;
      state.fpcPrimaryCare.error = false;
      state.fpcPrimaryCare.loading = false;
    },
    getFpcPrimaryCareLoading: (state: IProfileState): void => {
      state.fpcPrimaryCare.loading = true;
    },
    getFpcPrimaryCareError: (state: IProfileState): void => {
      state.fpcPrimaryCare.data = undefined;
      state.fpcPrimaryCare.error = true;
      state.fpcPrimaryCare.loading = false;
    },
    getPrimaryCareSuccess: (state: IProfileState, action: PayloadAction<IPrimaryCarePerMember>): void => {
      state.primaryCare.data = action.payload;
      state.primaryCare.error = false;
      state.primaryCare.loading = false;
    },
    getMedicareNudgeEligibilityLoading: (state: IProfileState): void => {
      state.medicareNudgeEligibility.data = undefined;
      state.medicareNudgeEligibility.error = undefined;
      state.medicareNudgeEligibility.loading = undefined;
    },
    getMedicareNudgeEligibilitySuccess: (state: IProfileState, action: PayloadAction<boolean>): void => {
      state.medicareNudgeEligibility.data = action.payload;
      state.medicareNudgeEligibility.loading = false;
    },
    getMedicareNudgeEligibilityError: (state: IProfileState): void => {
      state.medicareNudgeEligibility.data = undefined;
      state.medicareNudgeEligibility.loading = false;
      state.medicareNudgeEligibility.error = true;
    },
    getPrimaryCareLoading: (state: IProfileState): void => {
      state.primaryCare.loading = true;
    },
    getPrimaryCareError: (state: IProfileState): void => {
      state.primaryCare.data = undefined;
      state.primaryCare.error = true;
      state.primaryCare.loading = false;
    },
    getProviderVideoKeySuccess: (state: IProfileState, action: PayloadAction<IProviderVideo>): void => {
      state.providerVideoKey.data = action.payload;
    },
    setClaimsPreferencesSuccess: (state: IProfileState, action: PayloadAction<IClaimsPreferences>): void => {
      state.claimsPreferences.data = action.payload;
    },
    getFinancialPreferencesSuccess: (state: IProfileState, action: PayloadAction<IFinancialPreferences>): void => {
      state.financialPreferences.data = action.payload;
      state.financialPreferences.error = false;
      state.financialPreferences.loading = false;
    },
    getFinancialPreferencesLoading: (state: IProfileState): void => {
      state.financialPreferences.error = false;
      state.financialPreferences.loading = true;
    },
    getFinancialPreferencesError: (state: IProfileState): void => {
      state.financialPreferences.data = undefined;
      state.financialPreferences.error = true;
      state.financialPreferences.loading = false;
    },
    getProfileInfoForRallyPaySuccess: (
      state: IProfileState,
      action: PayloadAction<{ encryptedProfileInfo: string }>,
    ): void => {
      state.profileInfoForRallyPay.data = action.payload.encryptedProfileInfo;
      state.profileInfoForRallyPay.error = false;
      state.profileInfoForRallyPay.loading = false;
    },
    getPermissionsSuccess: (state: IProfileState, action: PayloadAction<IPermissions[]>): void => {
      state.permissions.data = action.payload;
      state.permissions.error = false;
      state.permissions.loading = false;
    },
    getProfileInfoForRallyPayLoading: (state: IProfileState): void => {
      state.profileInfoForRallyPay.error = false;
      state.profileInfoForRallyPay.loading = true;
    },
    getProfileInfoForRallyPayError: (state: IProfileState): void => {
      state.profileInfoForRallyPay.data = undefined;
      state.profileInfoForRallyPay.error = true;
      state.profileInfoForRallyPay.loading = false;
    },
    getOrderCovidTestKitLoading: (state: IProfileState): void => {
      state.orderCovidTestsInfo.data = undefined;
      state.orderCovidTestsInfo.error = false;
      state.orderCovidTestsInfo.loading = true;
    },
    setOrderCovidTestKitSuccess: (state: IProfileState, action: PayloadAction<IOrderCovidTestKitInfo>): void => {
      state.orderCovidTestsInfo.data = action.payload;
      state.orderCovidTestsInfo.loading = false;
    },
    setOrderCovidTestKitError: (state: IProfileState, action: PayloadAction<IOrderCovidTestKitInfo>): void => {
      state.orderCovidTestsInfo.data = action.payload;
      state.orderCovidTestsInfo.error = true;
      state.orderCovidTestsInfo.loading = false;
    },
    getAccountCardsLoading: (state: IProfileState): void => {
      state.accountCards.data = undefined;
      state.accountCards.error = undefined;
      state.accountCards.loading = undefined;
    },
    getAccountCardsSuccess: (state: IProfileState, action: PayloadAction<IAccountCard[]>): void => {
      state.accountCards.data = action.payload;
      state.accountCards.loading = false;
    },
    getAccountCardsError: (state: IProfileState): void => {
      state.accountCards.data = undefined;
      state.accountCards.loading = false;
      state.accountCards.error = true;
    },
    getUhcRewardsLoading: (state: IProfileState): void => {
      state.uhcRewards.data = undefined;
      state.uhcRewards.error = undefined;
      state.uhcRewards.loading = undefined;
    },
    getUhcRewardsSuccess: (state: IProfileState, action: PayloadAction<boolean>): void => {
      state.uhcRewards.data = action.payload;
      state.uhcRewards.loading = false;
    },
    getUhcRewardsError: (state: IProfileState): void => {
      state.uhcRewards.data = undefined;
      state.uhcRewards.loading = false;
      state.uhcRewards.error = true;
    },
  },
});

export const {
  changeSelectedProfile,
  getAccountCardsError,
  getAccountCardsLoading,
  getAccountCardsSuccess,
  getClaimsPreferencesError,
  getClaimsPreferencesLoading,
  getClaimsPreferencesSuccess,
  getCommunicationPreferencesError,
  getCommunicationPreferencesLoading,
  getCommunicationPreferencesSuccess,
  getFpcPrimaryCareError,
  getFpcPrimaryCareLoading,
  getFpcPrimaryCareSuccess,
  getHealthcareCoveragesError,
  getHealthcareCoveragesLoading,
  getHealthcareCoveragesSuccess,
  getMedicareNudgeEligibilityError,
  getMedicareNudgeEligibilityLoading,
  getMedicareNudgeEligibilitySuccess,
  getPrimaryCareError,
  getPrimaryCareLoading,
  getPrimaryCareSuccess,
  getProductsError,
  getProductsLoading,
  getProductsSuccess,
  getCspaRewardsError,
  getCspaRewardsLoading,
  getCspaRewardsSuccess,
  getProfileError,
  getProfileLoading,
  getProfileSuccess,
  getReferralsError,
  getReferralsLoading,
  getReferralsSuccess,
  getUhcRewardsError,
  getUhcRewardsLoading,
  getUhcRewardsSuccess,
  setClaimsPreferencesSuccess,
  getFinancialPreferencesSuccess,
  getFinancialPreferencesLoading,
  getFinancialPreferencesError,
  getProviderVideoKeySuccess,
  getProfileInfoForRallyPayError,
  getProfileInfoForRallyPayLoading,
  getProfileInfoForRallyPaySuccess,
  getOrderCovidTestKitLoading,
  setOrderCovidTestKitSuccess,
  setOrderCovidTestKitError,
  getPermissionsSuccess,
} = profile.actions;

export default profile.reducer;
