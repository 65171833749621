import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStateData } from './reducer.interfaces';
import { IBFFSection } from 'scripts/api/onboarding-tasks/onboarding-tasks-types';

export interface IOnboardingTasksState {
  onboardingTasksInfo: IStateData<IBFFSection>;
}

export const initialState: IOnboardingTasksState = {
  onboardingTasksInfo: {
    data: { meta: undefined, payload: undefined },
    error: undefined,
    loading: false,
  },
};

const onboardingTasks = createSlice({
  name: 'onboardingTasks',
  initialState,
  reducers: {
    getOnboardingTasksSuccess: (state: IOnboardingTasksState, action: PayloadAction<IBFFSection>): void => {
      const { meta, payload } = action.payload;
      state.onboardingTasksInfo.data.payload = payload;
      state.onboardingTasksInfo.data.meta = meta;
      state.onboardingTasksInfo.error = false;
      state.onboardingTasksInfo.loading = false;
    },
    getOnboardingTasksError: (state: IOnboardingTasksState): void => {
      state.onboardingTasksInfo.data = undefined;
      state.onboardingTasksInfo.error = true;
      state.onboardingTasksInfo.loading = false;
    },
  },
});

export const { getOnboardingTasksSuccess, getOnboardingTasksError } = onboardingTasks.actions;

export default onboardingTasks.reducer;
