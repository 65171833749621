import { combineReducers } from 'redux';
import app from 'scripts/reducers/app-reducer';
import claims from 'scripts/reducers/claims-service-reducer';
import incentives from 'scripts/reducers/incentives-service-reducer';
import ledger from 'scripts/reducers/ledger-service-reducer';
import locale from 'scripts/reducers/locale-reducer';
import livePerson from 'scripts/reducers/live-person-reducer';
import plans from 'scripts/reducers/plans-service-reducer';
import population from 'scripts/reducers/population-reducer';
import primaryCareProvider from 'scripts/reducers/primary-care-provider-reducer';
import profile from 'scripts/reducers/profile-service-reducer';
import speakEasy from 'scripts/reducers/speak-easy-service-reducer';
import taco from 'scripts/reducers/taco-service-reducer';
import targeting from 'scripts/reducers/targeting-service-reducer';
import user from 'scripts/reducers/user-service-reducer';
import activate from 'scripts/reducers/activate-service-reducer';
import onboarding from 'scripts/reducers/activate-onboarding-reducer';
import telemedicine from 'scripts/reducers/telemedicine-eligibility-reducer';
import connect from 'scripts/reducers/connect-service-reducer';
import rewards from 'scripts/reducers/rewards-service-reducer';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';
import eiRewards from 'scripts/reducers/ei-rewards-service-reducer';
import idCardAccessibility from 'scripts/reducers/id-card-accessibility-service-reducer';
import mobileHoops from 'scripts/reducers/mobile-hoops-reducer';
import epmpService from 'scripts/reducers/epmp-service-reducer';
import onboardingTasks from 'scripts/reducers/onboarding-tasks-reducer';

const reducers = {
  activate,
  onboarding,
  app,
  claims,
  connect,
  eiRewards,
  incentives,
  ledger,
  livePerson,
  loadingBar,
  locale,
  onboardingTasks,
  plans,
  population,
  primaryCareProvider,
  profile,
  speakEasy,
  rewards,
  taco,
  targeting,
  telemedicine,
  user,
  idCardAccessibility,
  mobileHoops,
  epmpService,
};

export default combineReducers(reducers);
