import { CoverageType, CoverageTypeCode } from 'scripts/api/api.interfaces';
import {
  IProfileUser,
  LineOfBusiness,
  MembershipCategory,
  IPlanCoverage,
  State,
  PrimaryPolicyNumber,
  VirtualExperienceType,
  FundingType,
  PermissionsDelegateType,
} from 'scripts/api/profile/profile.interfaces';
import { hasOnlyShipPlans } from '../plans/plans';
import Cookies from 'js-cookie';
import { destroyAll } from 'scripts/api/cache';
import {
  ACTIVATE_LINEAR_SESSION_KEY,
  CAMPAIGN_TRACK_EVENTS_SENT,
  SUPPRESS_BANNER_KEY,
} from 'scripts/util/constants/cookies.constant';
import { isBefore } from '../date/date';
import { IConfig } from '../constants/environment.interfaces';
import {
  clearArcadeSessionStorageItems,
  clearOnboardingCacheSessionStorageItems,
  clearMyUHCSessionStorageItems,
} from 'scripts/util/session/clear-session-storage';
import { isMedicalEffective, isPreEffective } from '../profile/profile';

export const isCS = (user: IProfileUser): boolean => {
  return user?.lineOfBusiness === LineOfBusiness.CS;
};

export const isDsnp = (user: IProfileUser): boolean => {
  return !!user?.memberFeatures?.isDsnp;
};

export const isEI = (user: IProfileUser): boolean => {
  return user?.lineOfBusiness === LineOfBusiness.EI;
};

export const isDhmo = (user: IProfileUser): boolean => {
  return user.memberFeatures.pcdEligible;
};

export const isNavNowUser = (user: IProfileUser): boolean => {
  return user?.planCoverages.some(cov => cov?.planFeatures?.virtualExperienceType === VirtualExperienceType.OVC);
};

/**
 * Checks if a user is an Empire user.
 * @param user
 * @returns true if the user is an Empire user; otherwise false.
 */
export const isEmpire = (user: IProfileUser): boolean => {
  return user?.membershipCategory === MembershipCategory.EMPIRE;
};

export const isExpatriate = (user: IProfileUser): boolean => {
  return user?.membershipCategory === MembershipCategory.EXPATRIATE;
};

export const isMobileAppEligible = (user: IProfileUser): boolean => {
  return isEI(user) && !isEmpire(user) && !isExpatriate(user);
};

export const isLoggedInUserSelected = (currentUser: IProfileUser, selectedUser: IProfileUser): boolean => {
  return currentUser.dependentSeqNum === selectedUser.dependentSeqNum;
};

export const isMR = (user: IProfileUser): boolean => {
  return user?.lineOfBusiness === LineOfBusiness.MR;
};
/** All Oxford and UHG_LEVEL_FUNDED members are Polaris users
 */
export const isPolaris = (user: IProfileUser): boolean => {
  return (
    user.membershipCategory === MembershipCategory.OXFORD ||
    user.membershipCategory === MembershipCategory.UHG_LEVEL_FUNDED ||
    (user.memberFeatures && user.memberFeatures.isPolaris)
  );
};

export const isPrime = (user: IProfileUser): boolean => {
  return user.memberFeatures && user.memberFeatures.isPrime;
};

export const isTufts = (user: IProfileUser): boolean => {
  return user.memberFeatures && user.memberFeatures.isTufts;
};

export const isUSP = (user: IProfileUser): boolean => {
  return isPolaris(user) || isTufts(user) || isPrime(user);
};

export const isFundingTypeMMP = (user: IProfileUser): boolean => {
  return checkForFundingType(user, FundingType.MMP);
};

export const isGated = (user: IProfileUser): boolean => {
  return user.memberFeatures?.gated;
};

export const isUHCWest = (user: IProfileUser): boolean => {
  return user.membershipCategory === MembershipCategory.UHC_WEST;
};

export const isCSNewJersey = (user: IProfileUser): boolean => {
  return isCS(user) && user.userInfo && user.userInfo.state === State.NJ;
};

export const isWAUser = (user: IProfileUser): boolean => user?.userInfo?.state === State.WA;

export const isMSCANUser = (user: IProfileUser): boolean => {
  return user.primaryPolicyNumber === PrimaryPolicyNumber.MSCAN;
};

export const isTSSTKUser = (user: IProfileUser): boolean => {
  return user.primaryPolicyNumber === PrimaryPolicyNumber.TXSTK;
};

export const isHWCCRLO = (user: IProfileUser): boolean => {
  return user.primaryPolicyNumber === PrimaryPolicyNumber.HWCCRLO;
};

export const isNCCCERMA = (user: IProfileUser): boolean => {
  return user.primaryPolicyNumber === PrimaryPolicyNumber.NCCCERMA;
};

export const isExchangePremiumPayments = (user: IProfileUser): boolean => {
  return (
    (user.membershipCategory === MembershipCategory.EXCHANGE ||
      user.membershipCategory === MembershipCategory.PUBLIC_EXCHANGE) &&
    user.memberFeatures.premiumPaymentEligible
  );
};

export const getEarliestStartDate = (planCoverages: IPlanCoverage[]): string => {
  let earliest = new Date(9999, 0, 1).toISOString();
  planCoverages.forEach(c => {
    if (isBefore(c.planPeriod.startDate, earliest)) {
      earliest = c.planPeriod.startDate;
    }
  });
  return earliest;
};

export const getValidEndDate = (planCoverages: IPlanCoverage[]): string | undefined => {
  const medicalCoverage = planCoverages.filter(cov => cov.coverageType === CoverageType.Medical)[0];
  if (medicalCoverage && medicalCoverage.planPeriod) {
    const possibleEndDate = medicalCoverage.planPeriod.endDate;
    if (isBefore(possibleEndDate, new Date(9999, 1, 1))) {
      return possibleEndDate;
    }
  }
};

export const getViewIdLinkText = (user: IProfileUser): string => {
  if (isEmpire(user)) {
    return 'VIEW_COVERAGE_DETAILS';
  } else if (isCSNewJersey(user)) {
    return hasOnlyShipPlans(user.planCoverages) ? 'VIEW_MEMBER_INSURANCE_CARDS' : 'VIEW_MEMBER_CARD';
  } else if (hasOnlyShipPlans(user.planCoverages)) {
    return 'PRINT_INSURANCE_CARDS';
  } else if (isCS(user)) {
    return 'PRINT_CARD';
  } else {
    return 'PRINT_CARDS';
  }
};

export const concatPvrc = (user: IProfileUser): string | undefined =>
  user.pvrc ? user.pvrc.planVariationCode + user.pvrc.reportingCode : undefined;

export const isINHCC = (user: IProfileUser): boolean => {
  return user.primaryPolicyNumber === PrimaryPolicyNumber.INHCC;
};

export const removeSession = (rallyId: string): void => {
  clearArcadeSessionStorageItems(rallyId);
  clearOnboardingCacheSessionStorageItems();
  clearMyUHCSessionStorageItems();
  Cookies.remove(ACTIVATE_LINEAR_SESSION_KEY);
  Cookies.remove(SUPPRESS_BANNER_KEY);
  Cookies.remove(CAMPAIGN_TRACK_EVENTS_SENT);
  destroyAll();
};

export const isUAWMember = (user: IProfileUser, config: IConfig): boolean => {
  const uawGroupNumbers = (config.ARCADE_WEB_MR_UAW_GROUP_NUMBERS || '').split(',');
  return user.planCoverages.some(({ groupNumber }) => groupNumber && uawGroupNumbers.includes(groupNumber));
};

export const isMaOrMapd = (coverageTypeCode: CoverageTypeCode): boolean => {
  return coverageTypeCode === CoverageTypeCode.MA || coverageTypeCode === CoverageTypeCode.MAPD;
};

export const isMaMAPDIndividualFunding = (userProfile: IProfileUser): boolean | undefined => {
  return userProfile?.planCoverages.some(
    cov => isMaOrMapd(cov.coverageTypeCode) && cov.planFeatures.fundingArrangementType === FundingType.Individual,
  );
};

export const isInPilotSuppressedProductCodes = (
  userProfile: IProfileUser,
  pilotSuppressedProductCodes: string[],
): boolean | undefined => {
  return userProfile?.planCoverages?.some(cov =>
    pilotSuppressedProductCodes.includes(cov.additionalCoverageInfo?.productCode),
  );
};

const checkForFundingType = (userProfile: IProfileUser, fundingType: FundingType): boolean => {
  return userProfile?.planCoverages?.some(cov => cov?.planFeatures?.fundingArrangementType === fundingType);
};

export const isFundingTypeFI = (userProfile: IProfileUser): boolean => {
  return checkForFundingType(userProfile, FundingType.FullyInsured);
};

export const isFundingTypeASO = (userProfile: IProfileUser): boolean => {
  return checkForFundingType(userProfile, FundingType.AdministrativeServices);
};

export const isCSDsnpEffective = (profile: IProfileUser): boolean =>
  isCS(profile) && isDsnp(profile) && isMedicalEffective(profile.planCoverages);

export const isMrEffective = (profile: IProfileUser): boolean =>
  isMR(profile) && isMedicalEffective(profile.planCoverages);

export const isCSDsnpPreEffective = (profile: IProfileUser): boolean =>
  isCS(profile) && isDsnp(profile) && isPreEffective(profile.planCoverages);

export const isMrPreEffective = (profile: IProfileUser): boolean =>
  isMR(profile) && isPreEffective(profile.planCoverages);

export const isSAMUser = (delegateType: string): boolean => delegateType === PermissionsDelegateType.HOH;
